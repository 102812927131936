// React
import React, {useState, useEffect} from 'react';
import {NotificationManager} from 'react-notifications';

// CSS
import './Items.css';

// Material UI
// import Grid from '@material-ui/core/Grid';

// Imagenes
// import cajonera from '../img/cajonera.png';

// Iconos
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';



function Items(props) {
    let dataItems = {
        beds: {
            img: '<svg width="81" height="56" viewBox="0 0 81 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M72.9 20V8C72.9 3.6 69.255 0 64.8 0H16.2C11.745 0 8.1 3.6 8.1 8V20C3.645 20 0 23.6 0 28V48H5.3865L7.452 54.12C7.857 55.24 8.91 56 10.125 56C10.7129 55.9991 11.286 55.8176 11.7649 55.4808C12.2438 55.144 12.6048 54.6684 12.798 54.12L14.8635 48H66.177L68.2425 54.12C68.607 55.24 69.66 56 70.875 56C71.4629 55.9991 72.036 55.8176 72.5149 55.4808C72.9938 55.144 73.3548 54.6684 73.548 54.12L75.6135 48H81V28C81 23.6 77.355 20 72.9 20ZM36.45 20H16.2V12C16.2 9.8 18.0225 8 20.25 8H36.45V20ZM64.8 20H44.55V8H60.75C62.9775 8 64.8 9.8 64.8 12V20Z" fill="black"/></svg>',
            value: 1.2
        },
        refrigerador:{
            img: '<svg width="38" height="74" viewBox="0 0 38 74" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.5835 0H2.96082C1.32624 0 0 1.3246 0 2.96V26.5158H37.5426V2.96C37.5435 1.3246 36.218 0 34.5835 0ZM6.62546 17.7921C6.62546 18.2829 6.22751 18.6792 5.73746 18.6792C5.2466 18.6792 4.84946 18.2829 4.84946 17.7921V8.72377C4.84946 8.23291 5.2466 7.8366 5.73746 7.8366C6.22751 7.8366 6.62546 8.23291 6.62546 8.72377V17.7921Z" fill="black"/><path d="M0.000488281 28.2762V68.769C0.000488281 70.4028 1.32591 71.729 2.96213 71.729H6.18113V72.4337C6.18113 73.2986 6.88248 74 7.74664 74C8.61079 74 9.31297 73.2978 9.31297 72.4337V71.729H28.2315V72.4337C28.2315 73.2986 28.9328 74 29.7978 74C30.662 74 31.3633 73.2978 31.3633 72.4337V71.729H34.5815C36.2169 71.729 37.5431 70.4036 37.5431 68.769V28.2762H0.000488281ZM6.62513 47.1989C6.62513 47.6897 6.22717 48.086 5.73713 48.086C5.24626 48.086 4.84913 47.6897 4.84913 47.1989V33.8402C4.84913 33.3494 5.24626 32.953 5.73713 32.953C6.22717 32.953 6.62513 33.3494 6.62513 33.8402V47.1989Z" fill="black"/></svg>',
            value: 1
        },
        furniture:{
            img: '<svg width="81" height="74" viewBox="0 0 81 74" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.1 0C3.645 0 0 3.7 0 8.22222V20.5556H81V8.22222C81 6.04155 80.1466 3.9502 78.6276 2.40823C77.1085 0.866267 75.0483 0 72.9 0H8.1ZM32.4 8.22222H48.6V12.3333H32.4V8.22222ZM0 24.6667V45.2222H81V24.6667H0ZM32.4 32.8889H48.6V37H32.4V32.8889ZM0 49.3333V61.6667C0 63.8473 0.85339 65.9387 2.37243 67.4807C3.89148 69.0226 5.95175 69.8889 8.1 69.8889V74H16.2V69.8889H64.8V74H72.9V69.8889C77.3955 69.8889 81 66.23 81 61.6667V49.3333H0ZM32.4 57.5556H48.6V61.6667H32.4V57.5556Z" fill="black"/></svg>',
            value: 0.5
        },
        oven:{
            img: '<svg width="81" height="56" viewBox="0 0 81 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.1 0C5.95175 0 3.89148 0.842854 2.37243 2.34315C0.85339 3.84344 0 5.87827 0 8V48C0 50.1217 0.85339 52.1566 2.37243 53.6569C3.89148 55.1571 5.95175 56 8.1 56H72.9C75.0483 56 77.1085 55.1571 78.6276 53.6569C80.1466 52.1566 81 50.1217 81 48V8C81 5.87827 80.1466 3.84344 78.6276 2.34315C77.1085 0.842854 75.0483 0 72.9 0H8.1ZM8.1 8H56.7V48H8.1V8ZM68.85 8C69.9241 8 70.9543 8.42143 71.7138 9.17157C72.4733 9.92172 72.9 10.9391 72.9 12C72.9 13.0609 72.4733 14.0783 71.7138 14.8284C70.9543 15.5786 69.9241 16 68.85 16C67.7759 16 66.7457 15.5786 65.9862 14.8284C65.2267 14.0783 64.8 13.0609 64.8 12C64.8 10.9391 65.2267 9.92172 65.9862 9.17157C66.7457 8.42143 67.7759 8 68.85 8ZM16.2 16V24H48.6V16H16.2ZM68.85 24C69.9241 24 70.9543 24.4214 71.7138 25.1716C72.4733 25.9217 72.9 26.9391 72.9 28C72.9 29.0609 72.4733 30.0783 71.7138 30.8284C70.9543 31.5786 69.9241 32 68.85 32C67.7759 32 66.7457 31.5786 65.9862 30.8284C65.2267 30.0783 64.8 29.0609 64.8 28C64.8 26.9391 65.2267 25.9217 65.9862 25.1716C66.7457 24.4214 67.7759 24 68.85 24Z" fill="black"/></svg>',
            value: 0.6
        },
        sofa:{
            img: '<svg width="89" height="65" viewBox="0 0 89 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5227 8.125C46.5227 3.61562 50.1232 0 54.6136 0H68.7727C73.2227 0 76.8636 3.65625 76.8636 8.125V16.9C72.1709 18.5656 68.7727 23.0344 68.7727 28.3156V36.5625H46.5227V8.125ZM20.2273 28.275V36.5625H42.4773V8.125C42.4773 3.61562 38.8768 0 34.3864 0H20.2273C15.7773 0 12.1364 3.65625 12.1364 8.125V16.8594C16.8291 18.525 20.2273 23.0344 20.2273 28.275ZM79.5336 20.4344C75.5691 21.0844 72.8182 24.8625 72.8182 28.925V40.625H16.1818V28.4375C16.1818 26.2826 15.3294 24.216 13.812 22.6923C12.2947 21.1685 10.2368 20.3125 8.09091 20.3125C5.94507 20.3125 3.88711 21.1685 2.36977 22.6923C0.852433 24.216 0 26.2826 0 28.4375V48.75C0 53.2188 3.64091 56.875 8.09091 56.875V65H16.1818V56.875H72.8182V65H80.9091V56.875C85.3591 56.875 89 53.2188 89 48.75V28.4375C89 23.5219 84.5905 19.5812 79.5336 20.4344Z" fill="black"/></svg>',
            value: 1.5
        },
        tv:{
            img: '<svg width="65" height="49" viewBox="0 0 65 49" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.1194 46.5494C10.1194 46.0126 10.3327 45.4978 10.7122 45.1183C11.0918 44.7387 11.6065 44.5255 12.1433 44.5255H52.621C53.1578 44.5255 53.6726 44.7387 54.0521 45.1183C54.4317 45.4978 54.6449 46.0126 54.6449 46.5494C54.6449 47.0861 54.4317 47.6009 54.0521 47.9805C53.6726 48.36 53.1578 48.5732 52.621 48.5732H12.1433C11.6065 48.5732 11.0918 48.36 10.7122 47.9805C10.3327 47.6009 10.1194 47.0861 10.1194 46.5494ZM8.09554 0H56.6688C56.6688 0 64.7643 0 64.7643 8.09554V32.3822C64.7643 32.3822 64.7643 40.4777 56.6688 40.4777H8.09554C8.09554 40.4777 0 40.4777 0 32.3822V8.09554C0 8.09554 0 0 8.09554 0Z" fill="black"/></svg>',
            value: 0.25
        },
        washerdryer:{
            img: '<svg width="45" height="53" viewBox="0 0 45 53" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M44.4327 13.1443V48.75C44.4327 51.0816 42.5252 52.9884 40.1943 52.9884H4.23834C1.9068 52.9884 0 51.0816 0 48.75V13.1443H44.4327ZM21.733 17.1107C30.04 17.1107 36.7737 23.8451 36.7737 32.1515C36.7737 40.4585 30.04 47.1922 21.733 47.1922C13.4266 47.1922 6.69226 40.4585 6.69226 32.1515C6.69226 23.8451 13.4266 17.1107 21.733 17.1107Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M4.23834 0H40.1943C42.5252 0 44.4327 1.90744 44.4327 4.23834V11.2815H0V4.23834C0 1.90744 1.9068 0 4.23834 0ZM5.77544 3.86346H12.7144C13.8805 3.86346 14.8336 4.81719 14.8336 5.98264V6.29794C14.8336 7.46404 13.8805 8.41712 12.7144 8.41712H5.77544C4.60999 8.41712 3.65626 7.46404 3.65626 6.29794V5.98264C3.65626 4.81719 4.60999 3.86346 5.77544 3.86346ZM34.0142 4.38143H39.844V8.17627H34.0142V4.38143ZM18.2133 4.38143H22.0082V8.17627H18.2133V4.38143ZM22.9749 4.38143H26.769V8.17627H22.9749V4.38143Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M21.7296 19.6165C25.1919 19.6165 28.3272 21.0202 30.5959 23.2883C32.8646 25.5564 34.2687 28.6908 34.2687 32.1515C34.2687 35.6129 32.8646 38.7473 30.5959 41.0154C28.3272 43.2834 25.1919 44.6872 21.7296 44.6872C18.2673 44.6872 15.1327 43.2834 12.864 41.0154C10.5953 38.7473 9.19116 35.6129 9.19116 32.1515C9.19116 28.6908 10.5953 25.5564 12.864 23.2883C15.1327 21.0202 18.2673 19.6165 21.7296 19.6165ZM10.3032 33.2496C13.6068 34.4241 17.5257 35.1046 21.7296 35.1046C25.9341 35.1046 29.8531 34.4241 33.1561 33.2496C33.1904 32.8883 33.2085 32.5218 33.2085 32.1515C33.2085 28.9834 31.9229 26.1138 29.8466 24.0374C27.7696 21.9616 24.8998 20.6764 21.7296 20.6764C18.56 20.6764 15.6903 21.9616 13.6133 24.0374C11.5369 26.1138 10.2514 28.9834 10.2514 32.1515C10.2514 32.5218 10.2695 32.8883 10.3032 33.2496Z" fill="black"/></svg>',
            value: 0.5
        },
        dining:{
            img: '<svg width="78" height="71" viewBox="0 0 78 71" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M72.775 29.9754H71.5162V45.4745H69.0772V42.0915V40.9113V25.963L65.4581 23.8387H64.4353V36.4268H54.7582V37.607V41.6194L50.9031 35.404H26.3563L22.5012 41.6194V37.607V36.4268H12.8241V23.8387H11.8013L8.18227 25.963V40.9113V42.0915V45.4745H5.74332V29.9754H4.48451L0 32.6504V50.9818V52.4767V68.6838H1.80954L2.36027 67.8184V52.4767H3.85511V63.1766H5.27127L5.74332 62.2324V52.4767H8.10359V55.309H9.59843L9.99181 54.6009V52.398H12.1947V68.6052H14.0043L14.4763 67.6611V51.6112L15.6565 49.8017V63.1766H17.0726L17.5447 62.2324V46.9694V45.4745H12.7455V42.0915H18.0167V55.309H19.5116L19.9049 54.5222V41.3834L20.849 39.9672V44.137L20.5343 44.6878V70.1H23.524V47.5988H26.5137V58.1413H28.874V47.5988H47.7561V58.1413H50.1164V47.5988H53.4994V70.0213H56.4891V44.6878L56.1744 44.137V39.9672L57.1185 41.3834V54.5222L57.5119 55.309H59.0067V42.0915H64.278V45.4745H59.4788V46.9694V62.2324L59.9508 63.1766H61.367V49.8017L62.5471 51.6112V67.7397L63.0192 68.6838H64.8287V52.4767H67.0316V54.6796L67.425 55.3877H68.9199V52.5553H71.2801V62.3111L71.7522 63.2552H73.1683V52.4767H74.6632V67.8971L75.2139 68.7625H77.0235V52.4767V50.9818V32.6504L72.775 29.9754ZM10.0705 45.4745V42.0915H11.3293V45.4745H10.0705ZM65.9302 45.4745V42.0915H67.189V45.4745H65.9302Z" fill="black"/><path d="M35.8762 22.5799V22.6586C35.8762 24.1534 37.135 25.4123 38.6299 25.4123C40.1247 25.4123 41.3835 24.1534 41.3835 22.6586V22.5799H35.8762Z" fill="black"/><path d="M40.1248 15.1057V12.7455H39.4167V0H37.8432V12.7455H37.1351V15.1057C34.6175 15.8138 32.7292 18.0954 32.7292 20.7704C32.7292 21.2424 32.8079 21.7145 32.8866 22.1865H44.3732C44.4519 21.7145 44.5306 21.3211 44.5306 20.7704C44.5306 18.0167 42.6424 15.7351 40.1248 15.1057Z" fill="black"/></svg>',
            value: 2
        },
        desk:{
            img: '<svg width="81" height="52" viewBox="0 0 81 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.05 6.10352e-05H76.95C79.1775 6.10352e-05 81 1.80006 81 4.00006C81 6.20006 79.1775 8.00006 76.95 8.00006V52.0001H68.85V44.0001H52.65V52.0001H44.55V8.00006H12.15V52.0001H4.05V8.00006C1.8225 8.00006 0 6.20006 0 4.00006C0 1.80006 1.8225 6.10352e-05 4.05 6.10352e-05ZM56.7 18.0001V20.0001H64.8V18.0001C64.8 16.8801 63.909 16.0001 62.775 16.0001H58.725C57.591 16.0001 56.7 16.8801 56.7 18.0001ZM56.7 34.0001V36.0001H64.8V34.0001C64.8 32.8801 63.909 32.0001 62.775 32.0001H58.725C57.591 32.0001 56.7 32.8801 56.7 34.0001Z" fill="black"/></svg>',
            value: 0.75
        },
        wardrobe:{
            img: '<svg width="65" height="82" viewBox="0 0 65 82" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.125 0C5.97012 0 3.90349 0.863926 2.37976 2.40172C0.856024 3.93952 0 6.02522 0 8.2V69.7C0 74.251 3.61562 77.9 8.125 77.9V82H16.25V77.9H48.75V82H56.875V77.9C61.3844 77.9 65 74.251 65 69.7V8.2C65 6.02522 64.144 3.93952 62.6202 2.40172C61.0965 0.863926 59.0299 0 56.875 0H8.125ZM8.125 8.2H28.4375V69.7H8.125V8.2ZM36.5625 8.2H56.875V69.7H36.5625V8.2ZM16.25 32.8V45.1H24.375V32.8H16.25ZM40.625 32.8V45.1H48.75V32.8H40.625Z" fill="black"/></svg>',
            value: 3.2
        },
    }
    
    useEffect(()=>{
        let tempData = props.data
        if(!tempData.find(e => e.type === props.type)){
            setQty(0)
        }
    },[props.data, props.setData])

    const alertN = (type, msg) => {
        if(type === "s"){
            NotificationManager.success(msg,"", 1000);
        } else if(type === "e") {
            NotificationManager.error(msg,"", 1000);
        } else if(type === "i") {
            NotificationManager.info(msg,"", 1000);
        }
    }

    const [qty, setQty] = useState(0)

    const addQty = ()=> {
        let tempQty = qty
        let tempData = props.data
        tempQty = tempQty + 1
        if(tempData.find(e => e.type === props.type)){
            tempData.find(e => e.type === props.type).qty = tempQty
        } else {
            tempData.push({type:props.type, qty:tempQty, value:dataItems[props.type].value  })
        }
        props.setData([...tempData])
        setQty(tempQty)
    }
    const remQty = ()=> {
        let tempQty = qty
        let tempData = props.data
        if(tempQty > 0){
            tempQty = tempQty - 1
            if(tempData.find(e => e.type === props.type)){
                tempData.find(e => e.type === props.type).qty = tempQty
            } else {
                tempData.push({type:props.type, qty:tempQty, value:dataItems[props.type].value })
            }
            props.setData([...tempData])
        }else {
            alertN("i", "Value is 0")
        };
        setQty(tempQty)
    }

    const writeQty = (e)=>{
        if(+e.target.value >= 0){
            let tempQty = +e.target.value
            let tempData = props.data
            setQty(tempQty)
            if(tempData.find(e => e.type === props.type)){
                tempData.find(e => e.type === props.type).qty = tempQty
            } else {
                tempData.push({type:props.type, qty:tempQty })
            }
            props.setData([...tempData])
        } else{
            alertN("e", "Value Invalid")
        }
    }
    return (
        <div item className="masterItems">
            <div className="items">
                <div dangerouslySetInnerHTML={{__html: dataItems[props.type].img}} />
                <h4>{props.name}</h4>
            </div>
            <div className="contentInput">
                <div className="buttonLeft" onClick={remQty}><RemoveIcon></RemoveIcon></div>
                <input type="text" value={qty} onChange={writeQty} />
                <div className="buttonRight" onClick={addQty}><AddIcon></AddIcon></div>
            </div>
        </div>
    )
}
export default Items;